


'use strict';

const email = document.getElementById("email");
const phone = document.getElementById("phone");
const emailmsg = document.getElementById("emailmsg");
const phonemsg = document.getElementById("phonemsg");
//const msg = document.getElementById("msg");
const form = document.getElementById("form");

const phoneRegex = /^(?:1[-. ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

var validate = function(event) {
  var valid = true;
  event.preventDefault();
  event.stopPropagation();

  // Special validations - must have one contact method
  if (email.value == "" && phone.value == "") {
    emailmsg.textContent = "We need at least one way to contact you. While we have many strengths, we are not telepathic.";
    phonemsg.textContent = "We need at least one way to contact you. While we have many strengths, we are not telepathic.";
    email.required = true;
    phone.required = true;
    valid = false;
  } else {
    emailmsg.textContent = "Your email address does not appear to be valid.";
    phonemsg.textContent = "Your phone number does not appear to be valid.";
    email.required = false;
    phone.required = false;
  }

  // Reformat the phone number to a standard format
  if (phoneRegex.test(phone.value)) {
    phone.value = phone.value.replace(phoneRegex, "($1) $2-$3");
  }

  // Check remaining form fields using html5 API
  if (form.checkValidity() === false) {
    valid = false;
  }

  form.classList.add('was-validated');

  if (valid) {
    // If the form is valid execute the recaptcha
    grecaptcha.execute();
  }
}

// Actual submission function called by the recaptcha
var onsubmit = function () {
  return function () {
    return new Promise(function(resolve, reject) {  
      //var response = grecaptcha.getResponse();
      //console.log(response);
      $.post(form.action, $('#form').serialize())
          .done(function( data, textStatus, jqXHR ) {
            $('#contactFail').modal({show: false});
            $('#contactSent').modal({show: true});
            resolve();
          })
          .fail(function( jqXHR, textStatus, errorThrown) {
            $('#contactSent').modal({show: false});
            $('#contactFail').modal({show: true});
            reject();
          });
      grecaptcha.reset();
    });
  };
}(); 

function onload() {
  // Add validation hook to the form submission
  form.addEventListener('submit', validate);
};


// (function() {
//   'use strict';

//   const email = document.getElementById("email");
//   const phone = document.getElementById("phone");
//   const emailmsg = document.getElementById("emailmsg");
//   const phonemsg = document.getElementById("phonemsg");
//   const msg = document.getElementById("msg");
//   const form = document.getElementById("form");

//   const phoneRegex = /^(?:1[-. ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

//   window.addEventListener('load', function() {

//     // Fetch all the forms we want to apply custom Bootstrap validation styles to
//     var form = document.getElementsByClassName('needs-validation');

//     // Loop over them and prevent submission
//     var validation = Array.prototype.filter.call(forms, function(form) {
//       form.addEventListener('submit', function(event) {

//         var valid = true;
//         event.preventDefault();

//         // Special validations - must have one contact method
//         if (email.value == "" && phone.value == "") {
//           emailmsg.textContent = "We need at least one way to contact you. While we have many strengths, we are not telepathic.";
//           phonemsg.textContent = "We need at least one way to contact you. While we have many strengths, we are not telepathic.";
//           email.required = true;
//           phone.required = true;

//           event.stopPropagation();
//           valid = false;
//         } else {
//           emailmsg.textContent = "Your email address does not appear to be valid.";
//           phonemsg.textContent = "Your phone number does not appear to be valid.";
//           email.required = false;
//           phone.required = false;
//         }

//         // Reformat the phone number to a standard format
//         if (phoneRegex.test(phone.value)) {
//           phone.value = phone.value.replace(phoneRegex, "($1) $2-$3");
//         }

//         // Deal with the captcha
//         var captchaValid = false;
//         var resp = grecaptcha.getResponse();
        
//         if (resp) {
//           msg.textContent = '';
//           $('.g-recaptcha div div iframe').removeClass("captcha-invalid");
//         } else {
//           msg.textContent = 'Please click the checkbox above, and complete any requested tasks if prompted. '
//               + 'We need to be convinced that your are not an evil robot, bent upon world domination.';
//           $('.g-recaptcha div div iframe').addClass("captcha-invalid");
//           event.stopPropagation();
//           valid = false;
//         }

//         if (form.checkValidity() === false) {
//           event.stopPropagation();
//           valid = false;
//         }

//         form.classList.add('was-validated');

//         if (valid) {

//         $.post(form.action, $('#form').serialize())
//           .done(function( data, textStatus, jqXHR ) {
//             $('#contactFail').modal({show: false});
//             $('#contactSent').modal({show: true});
//           })
//           .fail(function( jqXHR, textStatus, errorThrown) {
//             $('#contactSent').modal({show: false});
//             $('#contactFail').modal({show: true});
//           });

//         }
//       }, false);
//     });
//   }, false); // load
// })();






// $(document).ready(function(){

//     function setError(selector, msg) {
//       $(selector).removeClass('has-success')
//         .addClass('has-error')
//         .find('.with-errors').text(msg);
//     }
//     function setSuccess(selector) {
//       $(selector).removeClass('has-error')
//         .addClass('has-success')
//         .find('.with-errors').text('');
//     }
//     function setNothing(selector) {
//       $(selector).removeClass('has-error')
//         .removeClass('has-success')
//         .find('.with-errors').text('');
//     } 
  
//     function validate(failtest, validtest, target, msg) {
//       if (!failtest) {
//         setError(target, msg);
//         return false;
//       } else if (validtest) {
//         setSuccess(target);
//         return true;
//       } else {
//         setNothing(target);
//         return null;
//       }
//     }
  
//     const email = document.getElementById("email");
//     const phone = document.getElementById("phone");
//     const contact = document.getElementById("contactmsg");

//     email.addEventListener("input", function (event) {
//       if (email.validity.patternMismatch && phone.validity.patternMismatch) {
//         contact.value = "We need at least one way to contact you. While we have many strengths, we are not telepathic.";
//       } else {
//         contact.value = "";
//       }
//     });

//     phone.addEventListener("input", function (event) {
//       if (email.validity.patternMismatch && phone.validity.patternMismatch) {
//         contact.value = "We need at least one way to contact you. While we have many strengths, we are not telepathic.";
//       } else {
//         contact.value = "";
//       }
//     });


//     // Contact Form Validation
//     $('#contactForm').on('submit', function(e){
//       e.preventDefault();
//       // Name needs to be at least 3 characters
//       // var name = $('#name').val();
//       // var nameValid = validate((name.length >= 3), true, '#nameGroup', 
//       //   'Please tell us your name. "Hey You!" seems so impersonal.');
      
//       // // Company is optional
//       // var company = $('#company').val();
//       // validate(true, company.length, '#companyGroup');
  
//       // // Email test
//       // var email = $('#email').val();
//       // var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
//       // var emailValid = validate(!email.length || emailRegex.test(email), email.length,
//       //   '#emailGroup', 'Your email address does not appear to be valid');
  
//       // // Phone test
//       // var phone = $('#phone').val();
//       // phoneRegex = /^(\+?1(\-|\s*|\.))?(\([0-9]{3}\)\s*|[0-9]{3}(\-|\.|\s*))[0-9]{3}(\-|\s*|\.)[0-9]{4}$/;
//       // var phoneValid = validate(!phone.length || phoneRegex.test(phone), phone.length, 
//       //   '#phoneGroup', 'Your phone number does not appear to be valid');
  
//       // if (!(emailValid || phoneValid)) {
//       //   $('#contactmsg').text('epathic.We need at least one way to contact you. While we have many strengths, we are not tel');
//       // } else {
//       //   $('#contactmsg').text('');
//       // }
  
//       var captchaValid = false;
//       var resp = grecaptcha.getResponse();
//       console.log(resp);
//       if (resp) {
//         $('#msg').text('');
//         captchaValid = true;
//       } else {
//         $('#msg').html('Please click the checkbox below, and complete any requested tasks if prompted.<br>We need to be convinced that your are not an evil robot, bent upon world domination.');
//       }
  
//       if (captchaValid) {
  
//         $.post('/contact', $('#contactForm').serialize())
//           .done(function( data, textStatus, jqXHR ) {
//             $('#contactFail').modal({show: false});
//             $('#contactSent').modal({show: true})
//           })
//           .fail(function( jqXHR, textStatus, errorThrown) {
//             $('#contactSent').modal({show: false});
//             $('#contactFail').modal({show: true});
//           });
//       }
//     });

//     // Book Quotations Form 
//     $('#orderForm').on('submit', function(e){
//       e.preventDefault();
//       // Project title
//       var name = $('#title').val();
//       var nameValid = validate((name.length >= 3), true, '#nameGroup', 
//         'Please give your project a name so that we can reference it in our response.');
      
//       // Name needs to be at least 3 characters
//       var name = $('#name').val();
//       var nameValid = validate((name.length >= 3), true, '#nameGroup', 
//         'Please tell us your name. "Hey You!" seems so impersonal.');
      
//       // Company is optional
//       var company = $('#company').val();
//       validate(true, company.length, '#companyGroup');
  
//       // Email test
//       var email = $('#email').val();
//       var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
//       var emailValid = validate(!email.length || emailRegex.test(email), email.length,
//         '#emailGroup', 'Your email address does not appear to be valid');
  
//       // Phone test
//       var phone = $('#phone').val();
//       phoneRegex = /^(\+?1(\-|\s*|\.))?(\([0-9]{3}\)\s*|[0-9]{3}(\-|\.|\s*))[0-9]{3}(\-|\s*|\.)[0-9]{4}$/;
//       var phoneValid = validate(!phone.length || phoneRegex.test(phone), phone.length, 
//         '#phoneGroup', 'Your phone number does not appear to be valid');
  
//       if (!(emailValid || phoneValid)) {
//         $('#contactmsg').text('We need at least one way to contact you. While we have many strengths, we are not telepathic.');
//       } else {
//         $('#contactmsg').text('');
//       }
  
//       // pages test
//       var pages = $('#pages').val();
//       var pagesValid = validate((pages % 2) == 0, pages.length,
//         '#pagesGroup', 'The number of pages must be divisible by 2');

//       var quantity = $('#quantity').val();
//       var qtyValid = validate((quantity >= 25), quantity.length,
//         '#quantityGroup', 'The quantity must be 25 or more');

//       var captchaValid = false;
//       var resp = grecaptcha.getResponse();
//       // console.log(resp);
//       if (resp) {
//         $('#msg').text('');
//         captchaValid = true;
//       } else {
//         $('#msg').html('Please click the checkbox below, and complete any requested tasks if prompted.<br>We need to be convinced that your are not an evil robot, bent upon world domination.');
//       }
  
//       if (nameValid && 
//           (emailValid || phoneValid) &&
//           (emailValid !== false) &&
//           (phoneValid !== false) &&
//           (pagesValid !== false) &&
//           (qtyValid !== false) &&
//           captchaValid) {
  
//         $.post('/book', $('#orderForm').serialize())
//           .done(function( data, textStatus, jqXHR ) {
//             $('#contactFail').modal({show: false});
//             $('#contactSent').modal({show: true});
//           })
//           .fail(function( jqXHR, textStatus, errorThrown) {
//             $('#contactSent').modal({show: false});
//             $('#contactFail').modal({show: true});
//           });
//       }
//     });
//   });